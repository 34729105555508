var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"production-2.1.1.7770"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// import * as Sentry from '@sentry/nextjs'
// import { Integrations } from '@sentry/tracing'
// import SentryRRWeb from '@sentry/rrweb'
// import { Dedupe as DedupeIntegration } from '@sentry/integrations/dist/dedupe'
// import { Offline as OfflineIntegration } from '@sentry/integrations/dist/offline'
// import { getSentryReleaseVersion } from './helpers/config'
//
//
// const integrations = [
//   new Integrations.BrowserTracing(),
//   new DedupeIntegration(),
//   new OfflineIntegration(),
// ]
//
// if (process.env.NEXT_PUBLIC_ENABLE_RRWEB === 'true') {
//   integrations.push(new SentryRRWeb())
// }
//
// Sentry.init({
//   dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
//   debug: false,
//   integrations,
//   release: getSentryReleaseVersion(),
//   environment: process.env.NEXT_PUBLIC_ENV_MODE,
//   tracesSampleRate: 1.0,
//   normalizeDepth: 10,
// })
